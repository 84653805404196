<template>
  <component
    :is="components[item.type]"
    v-if="(editing || !item.isHidden) && item.type !== 'submit'"
    :key="item.id"
    :class="componentClasses"
    :uuid="uuid"
    :item="item"
    :styling="styling"
    :active="activeId === item.id"
    :editing="editing"
    :form-id="formIdProp"
    :compressor="compressor"
    :question-number="questionNumbers[item.id]"
    :page-break-allowed="pageBreakAllowed"
    :form-elements="formElements"
    :variables="variables"
    :is-child-component="isChildComponent"
    :index="index"
    v-bind="conditionalProps"
    @update="(value) => set(item, 'value', value)"
    @verified="$emit('verified')"
    @expired="$emit('expired')"
    @error="$emit('error')"
    @next="$emit('next')"
    @click="handleEvent('handle-setting', $event)"
    @delete="handleEvent('delete', $event)"
    @duplicate="handleEvent('duplicate', $event)"
    @hide="handleEvent('hide', $event)"
    @page-break="handleEvent('page-break', $event)"
    @form-value-changed="$emit('form-value-changed', $event)"
  />
</template>
<script setup>
// Imports
import { computed } from "vue";
import set from "lodash/set";

// Import Components

// Emits
const emits = defineEmits([
  "verified",
  "expired",
  "error",
  "next",
  "handle-setting",
  "delete",
  "duplicate",
  "hide",
  "page-break",
  "form-value-changed",
]);

// Props
const props = defineProps({
  components: {
    type: Object,
    default: () => ({}),
  },
  uuid: {
    type: String,
    required: true,
  },
  formId: {
    type: String,
    default: "",
  },
  item: {
    type: Object,
    required: true,
  },
  styling: {
    type: Object,
    required: true,
  },
  editing: {
    type: Boolean,
    default: false,
  },
  activeId: {
    type: String,
    default: "",
  },
  formElements: {
    type: Array,
    required: true,
  },
  variables: {
    type: Array,
    required: true,
  },
  compressor: {
    type: Function,
    default: null,
  },
  hasMultipleFields: {
    type: Boolean,
    default: false,
  },
  pageBreakAllowed: {
    type: Boolean,
    default: true,
  },
  device: {
    type: String,
    default: "",
  },
  isChildComponent: {
    type: Boolean,
    default: false,
  },
  index: {
    type: Number,
    default: 0,
  },
});

// Computed
const formIdProp = computed(() => {
  const componentRequiresFormId = ["paragraph", "column-layout"];

  return componentRequiresFormId.includes(props.item.type)
    ? props.formId
    : null;
});

const conditionalProps = computed(() => {
  if (["column-layout", "repeat-field"].includes(props.item.type)) {
    return {
      components: props.components,
      activeId: props.activeId,
      device: props.device,
    };
  }

  return {};
});

const questionNumbers = computed(() => {
  const questionIndex = props.formElements
    .filter(
      (el) =>
        (el.category === "input" || el.type === "repeat-field") &&
        !el.isHidden &&
        !el.componentFromRepeatField,
    )
    .reduce((obj, item, index) => {
      obj[item.id] = index + 1;
      return obj;
    }, {});
  return questionIndex;
});

const componentClasses = computed(() => {
  return { "child-component": props.isChildComponent };
});

// Methods
function handleEvent(emitOption, event) {
  let elementId = props.item.id;

  if (props.item.type === "column-layout" && props.item.components.length > 0) {
    elementId = event || elementId;
  } else if (props.item.type === "repeat-field") {
    elementId = event || elementId;
  }

  emits(emitOption, elementId);
}
</script>

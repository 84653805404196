export function useFormElement() {
  const choiceTypes = [
    "radio",
    "dropdown",
    "multiple-checkbox",
    "picture-checkbox",
    "matrix",
  ];

  function isChoiceType(element) {
    return choiceTypes.includes(element.type);
  }

  return {
    isChoiceType,
  };
}

export default {
  backgroundColor: "#ffffff",
  answerTextColor: "#101828",
  backgroundBrightness: 100, // possible value from 0 to 100 and we do things basis of that
  logoImage: {},
  formColor: "#ffffff00",
  fontColor: "#101828",
  descriptionFontColor: "#475467",
  fontFamily: "Inter",
  fontSize: "16",
  answerFontSize: "16",
  labelFontWeight: 700,
  labelBottomMargin: "4",
  sublabelFontSize: "12",
  sublabelFontWeight: 400,
  descriptionFontSize: "12",
  descriptionFontWeight: 400,
  descriptionBottomMargin: "8",
  optionFontSize: "14",
  optionFontWeight: 400,
  alignment: "Top-Bottom",
  multiPage: false,
  multiPageTransition: true,
  autoProgression: false,
  isRequiredMarkerVisible: true,
  css: "",
  placeholderColor: "#ffffff",
  placeholderTextColor: "#D0D5DD",
  borderColor: "#D0D5DD",
  buttonColor: "#00A3FF",
  buttonTextColor: "#ffffff",
  maxWidth: "750",
  italicFont: false,
  marginTop: "0",
  marginBottom: "12",
  formVerticalPadding: "52",
  formHorizontalPadding: "8",
  progressColor: "",
  logoBoxColor: "#00000000",
  logoSize: 60,
  inFormLogo: false,
  addInvisibleRecaptcha: false,
  isRtlLanguage: false,
  favicon: {},
  branding: {},
  showQuestionNumber: false,
  pageBreakPresent: false,
  allowOfflineMode: false,
  showProgressIndicators: true,
  showNavigationButtons: true,
  enableResumption: false,
  enablePartialSubmission: false,
  enableMultiLanguageSupport: false,
  timer: {
    timerEnabled: false,
    timeoutTitle: "Time is up!",
    countdownHours: [0, 0],
    countdownMinutes: [0, 0],
    countdownSeconds: [0, 0],
  },
};

<template>
  <div id="complex styles" />
</template>

<script>
import { addAlpha } from "@/helpers/utils.js";
import isEmpty from "lodash/isEmpty";

export default {
  props: {
    styling: {
      type: Object,
      required: true,
    },
  },
  computed: {
    placeholderTextColor() {
      return this.styling.placeholderTextColor || "#bababa";
    },
    borderColor() {
      return this.styling.borderColor || "#bababa";
    },
    placeholderColor() {
      return this.styling.placeholderColor || "white";
    },
    btnHoverTextColor() {
      return this.styling.buttonTextColor || "white";
    },
    primaryColor() {
      return this.styling.buttonColor || "#00A3FF";
    },
    formColor() {
      return this.styling.formColor || "#ffffff";
    },
    fontColor() {
      return this.styling.fontColor || "#575757";
    },
    btnFocusColor() {
      return this.styling.buttonColor
        ? addAlpha(this.styling.buttonColor, 0.4)
        : addAlpha("#00A3FF");
    },
    answerTextColor() {
      return this.styling.answerTextColor || "#2c2c2c";
    },
    answerFontSize() {
      return this.styling.answerFontSize || "14";
    },
    overlayColor() {
      // this is based on the background brightness
      if (this.styling.backgroundBrightness <= 100) {
        return "#000000";
      } else {
        return "#ffffff";
      }
    },
    overlayOpacity() {
      // this is based on the background brightness
      if (this.styling.backgroundBrightness <= 100) {
        return `${100 - this.styling.backgroundBrightness}%`;
      } else {
        return `${this.styling.backgroundBrightness - 100}%`;
      }
    },
    optionFontSize() {
      return this.styling.optionFontSize || "14";
    },
    descriptionFontColor() {
      return this.styling.descriptionFontColor || "#475467";
    },
  },
  watch: {
    placeholderTextColor: {
      immediate: true,
      handler() {
        document
          .querySelector(":root")
          .style.setProperty(
            "--placeholder-text-color",
            this.placeholderTextColor,
          );
      },
    },
    placeholderColor: {
      immediate: true,
      handler() {
        const rootStyle = document.querySelector(":root").style;
        rootStyle.setProperty("--placeholder-color", this.placeholderColor);
        rootStyle.setProperty(
          "--placeholder-color-fifty-opacity",
          addAlpha(this.placeholderColor, 0.5),
        );
      },
    },
    borderColor: {
      immediate: true,
      handler() {
        const rootStyle = document.querySelector(":root").style;
        rootStyle.setProperty("--border-color", this.borderColor);
      },
    },
    btnHoverTextColor: {
      immediate: true,
      handler() {
        document
          .querySelector(":root")
          .style.setProperty("--button-color--hover", this.btnHoverTextColor);
      },
    },
    primaryColor: {
      immediate: true,
      handler() {
        const rootStyle = document.querySelector(":root").style;
        rootStyle.setProperty("--primary-color", this.primaryColor);
        rootStyle.setProperty(
          "--primary-color-five-opacity",
          addAlpha(this.primaryColor, 0.061),
        );
        rootStyle.setProperty(
          "--primary-color-ten-opacity",
          addAlpha(this.primaryColor, 0.1),
        );
        rootStyle.setProperty(
          "--primary-color-fifteen-opacity",
          addAlpha(this.primaryColor, 0.15),
        );
        rootStyle.setProperty(
          "--primary-color-twenty-opacity",
          addAlpha(this.primaryColor, 0.2),
        );
        rootStyle.setProperty(
          "--primary-color-seventy-opacity",
          addAlpha(this.primaryColor, 0.7),
        );
      },
    },
    formColor: {
      immediate: true,
      handler() {
        document
          .querySelector(":root")
          .style.setProperty("--form-color", this.formColor);
      },
    },
    descriptionFontColor: {
      immediate: true,
      handler() {
        document
          .querySelector(":root")
          .style.setProperty(
            "--description-font-color",
            this.descriptionFontColor,
          );
      },
    },
    fontColor: {
      immediate: true,
      handler() {
        const rootStyle = document.querySelector(":root").style;
        rootStyle.setProperty("--font-color", this.fontColor);
        rootStyle.setProperty(
          "--font-color-five-opacity",
          addAlpha(this.fontColor, 0.061),
        );
        rootStyle.setProperty(
          "--font-color-ten-opacity",
          addAlpha(this.fontColor, 0.1),
        );
        rootStyle.setProperty(
          "--font-color-fifteen-opacity",
          addAlpha(this.fontColor, 0.15),
        );
        rootStyle.setProperty(
          "--font-color-fifty-opacity",
          addAlpha(this.fontColor, 0.5),
        );
        rootStyle.setProperty(
          "--font-color-seventy-opacity",
          addAlpha(this.fontColor, 0.7),
        );
        rootStyle.setProperty(
          "--font-color-eighty-opacity",
          addAlpha(this.fontColor, 0.8),
        );
      },
    },
    btnFocusColor: {
      immediate: true,
      handler() {
        document
          .querySelector(":root")
          .style.setProperty("--btn-focus-color", this.btnFocusColor);
      },
    },
    answerTextColor: {
      immediate: true,
      handler() {
        document
          .querySelector(":root")
          .style.setProperty(
            "--answer-input-color",
            this.styling.answerTextColor,
          );
      },
    },
    answerFontSize: {
      immediate: true,
      handler() {
        document
          .querySelector(":root")
          .style.setProperty(
            "--answer-input-font-size",
            `${this.styling.answerFontSize}px`,
          );
      },
    },
    overlayColor: {
      immediate: true,
      handler() {
        document
          .querySelector(":root")
          .style.setProperty("--overlay-color", this.overlayColor);
      },
    },
    overlayOpacity: {
      immediate: true,
      handler() {
        document
          .querySelector(":root")
          .style.setProperty("--overlay-opacity", this.overlayOpacity);
      },
    },
    optionFontSize: {
      immediate: true,
      handler() {
        document
          .querySelector(":root")
          .style.setProperty(
            "--option-input-font-size",
            `${this.styling.optionFontSize}px`,
          );
      },
    },
  },
};
</script>

<style>
.formester-input::placeholder {
  color: var(--placeholder-text-color);
  font-size: var(--font);
}
.formester-input:-ms-input-placeholder {
  color: calc(var(--answerFontSize) - 2px);
}
</style>

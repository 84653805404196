/*****
    This Mixin is used to dynamically import the components
    - In components object add a key representing the type of the component. Assign a value of `null` to this key.
    - Define a method to import the component
    NOTE: the method name should be camelCase of component type and ends with suffix "Import"
*****/

import { markRaw } from "vue";
import camelCase from "lodash/camelCase";

const importComponents = {
  data() {
    return {
      components: {
        calculation: null,
        welcome: null,
        header: null,
        subheader: null,
        paragraph: null,
        shorttext: null,
        url: null,
        email: null,
        number: null,
        name: null,
        phone: null,
        date: null,
        time: null,
        scheduler: null,
        longtext: null,
        dropdown: null,
        address: null,
        radio: null,
        "multiple-checkbox": null,
        file: null,
        signature: null,
        submit: null,
        "scale-rating": null,
        "star-rating": null,
        ranking: null,
        spacer: null,
        image: null,
        "form-video": null,
        "form-audio": null,
        "form-embed": null,
        recaptcha: null,
        "cf-turnstile": null,
        "auto-id": null,
        location: null,
        "thank-you": null,
        "picture-checkbox": null,
        paypal: null,
        stripe: null,
        matrix: null,
        "invisible-recaptcha": null,
        "column-layout": null,
        "repeat-field": null,
      },
    };
  },
  methods: {
    async importAllComponents() {
      const importPromises = Object.keys(this.components).map(
        async (component) => {
          try {
            return this[`${camelCase(component)}Import`]();
          } catch (e) {
            console.error(e);
          }
        },
      );

      await Promise.all(importPromises);
    },
    async calculationImport() {
      const module = await import(
        "@/components/builder/components/calculation.vue"
      );
      this.components["calculation"] = markRaw(module.default);
    },
    async welcomeImport() {
      const module = await import(
        "@/components/builder/components/welcome/welcome.vue"
      );
      this.components["welcome"] = markRaw(module.default);
    },
    async headerImport() {
      const module = await import(
        "@/components/builder/components/heading.vue"
      );
      this.components["header"] = markRaw(module.default);
    },
    async subheaderImport() {
      const module = await import(
        "@/components/builder/components/sub-heading.vue"
      );
      this.components["subheader"] = markRaw(module.default);
    },
    async paragraphImport() {
      const module = await import(
        "@/components/builder/components/paragraph.vue"
      );
      this.components["paragraph"] = markRaw(module.default);
    },
    async shorttextImport() {
      const module = await import(
        "@/components/builder/components/short-text.vue"
      );
      this.components["shorttext"] = markRaw(module.default);
    },
    async urlImport() {
      const module = await import(
        "@/components/builder/components/short-text.vue"
      );
      this.components["url"] = markRaw(module.default);
    },
    async emailImport() {
      const module = await import(
        "@/components/builder/components/short-text.vue"
      );
      this.components["email"] = markRaw(module.default);
    },
    async numberImport() {
      const module = await import(
        "@/components/builder/components/short-text.vue"
      );
      this.components["number"] = markRaw(module.default);
    },
    async nameImport() {
      const module = await import("@/components/builder/components/name.vue");
      this.components["name"] = markRaw(module.default);
    },
    async phoneImport() {
      const module = await import(
        "@/components/builder/components/phone/phone.vue"
      );
      this.components["phone"] = markRaw(module.default);
    },
    async dateImport() {
      const module = await import("@/components/builder/components/date.vue");
      this.components["date"] = markRaw(module.default);
    },
    async timeImport() {
      const module = await import("@/components/builder/components/time.vue");
      this.components["time"] = markRaw(module.default);
    },
    async schedulerImport() {
      const module = await import(
        "@/components/builder/components/scheduler.vue"
      );
      this.components["scheduler"] = markRaw(module.default);
    },
    async longtextImport() {
      const module = await import(
        "@/components/builder/components/long-text.vue"
      );
      this.components["longtext"] = markRaw(module.default);
    },
    async dropdownImport() {
      const module = await import(
        "@/components/builder/components/dropdown.vue"
      );
      this.components["dropdown"] = markRaw(module.default);
    },
    async addressImport() {
      const module = await import(
        "@/components/builder/components/address.vue"
      );
      this.components["address"] = markRaw(module.default);
    },
    async radioImport() {
      const module = await import("@/components/builder/components/radio.vue");
      this.components["radio"] = markRaw(module.default);
    },
    async multipleCheckboxImport() {
      const module = await import(
        "@/components/builder/components/multiple-checkbox.vue"
      );
      this.components["multiple-checkbox"] = markRaw(module.default);
    },
    async fileImport() {
      const module = await import("@/components/builder/components/file.vue");
      this.components["file"] = markRaw(module.default);
    },
    async signatureImport() {
      const module = await import(
        "@/components/builder/components/signature.vue"
      );
      this.components["signature"] = markRaw(module.default);
    },
    async submitImport() {
      const module = await import(
        "@/components/builder/components/submit/submit.vue"
      );
      this.components["submit"] = markRaw(module.default);
    },
    async scaleRatingImport() {
      const module = await import(
        "@/components/builder/components/scale-rating.vue"
      );
      this.components["scale-rating"] = markRaw(module.default);
    },
    async starRatingImport() {
      const module = await import(
        "@/components/builder/components/star-rating.vue"
      );
      this.components["star-rating"] = markRaw(module.default);
    },
    async rankingImport() {
      const module = await import(
        "@/components/builder/components/ranking.vue"
      );
      this.components["ranking"] = markRaw(module.default);
    },
    async spacerImport() {
      const module = await import("@/components/builder/components/spacer.vue");
      this.components["spacer"] = markRaw(module.default);
    },
    async imageImport() {
      const module = await import(
        "@/components/builder/components/image/image.vue"
      );
      this.components["image"] = markRaw(module.default);
    },
    async formVideoImport() {
      const module = await import(
        "@/components/builder/components/form-video.vue"
      );
      this.components["form-video"] = markRaw(module.default);
    },
    async formAudioImport() {
      const module = await import(
        "@/components/builder/components/form-audio.vue"
      );
      this.components["form-audio"] = markRaw(module.default);
    },
    async formEmbedImport() {
      const module = await import(
        "@/components/builder/components/form-embed.vue"
      );
      this.components["form-embed"] = markRaw(module.default);
    },
    async recaptchaImport() {
      const module = await import(
        "@/components/builder/components/recaptcha/recaptcha.vue"
      );
      this.components["recaptcha"] = markRaw(module.default);
    },
    async autoIdImport() {
      const module = await import(
        "@/components/builder/components/auto-id.vue"
      );
      this.components["auto-id"] = markRaw(module.default);
    },
    async locationImport() {
      const module = await import(
        "@/components/builder/components/location/location.vue"
      );
      this.components["location"] = markRaw(module.default);
    },
    async thankYouImport() {
      const module = await import(
        "@/components/builder/components/thank-you/thank-you.vue"
      );
      this.components["thank-you"] = markRaw(module.default);
    },
    async pictureCheckboxImport() {
      const module = await import(
        "@/components/builder/components/picture-choice.vue"
      );
      this.components["picture-checkbox"] = markRaw(module.default);
    },
    async matrixImport() {
      const module = await import("@/components/builder/components/matrix.vue");
      this.components["matrix"] = markRaw(module.default);
    },
    async paypalImport() {
      const module = await import(
        "@/components/builder/components/payments/index.vue"
      );
      this.components["paypal"] = markRaw(module.default);
    },
    async stripeImport() {
      const module = await import(
        "@/components/builder/components/payments/index.vue"
      );
      this.components["stripe"] = markRaw(module.default);
    },
    async invisibleRecaptchaImport() {
      const module = await import(
        "@/components/builder/components/recaptcha/invisible-recaptcha.vue"
      );
      this.components["invisible-recaptcha"] = markRaw(module.default);
    },
    async cfTurnstileImport() {
      const module = await import(
        "@/components/builder/components/recaptcha/turnstile.vue"
      );
      this.components["cf-turnstile"] = markRaw(module.default);
    },
    async columnLayoutImport() {
      const module = await import(
        "@/components/builder/components/column-layout.vue"
      );
      this.components["column-layout"] = markRaw(module.default);
    },
    async repeatFieldImport() {
      const module = await import(
        "@/components/builder/components/repeat-field.vue"
      );
      this.components["repeat-field"] = markRaw(module.default);
    },
  },
};

export default importComponents;
